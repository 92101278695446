import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getUser, getUserDocuments } from "../../api";
import Page from "../../components/Page";
import Card from "../../components/Card";
import Preview from "../../components/Preview";
import { ReactComponent as OpenInNewIcon } from "../../icons/OpenInNew.svg";
import { dateString } from "../../utils";
import "./UserHome.scss";

export default function UserHome() {
  const { username } = useParams();
  const [visitee, setVisitee] = useState({ name: "", username: "", email: "" });
  const [docs, setDocs] = useState([]);
  const [pageMessage, setPageMessage] = useState();

  useEffect(() => {
    setPageMessage({
      fetchStatus: "loading",
    });
    getUser(username)
      .then(({ data, status }) => {
        if (status === 200) {
          setVisitee(data);
          setPageMessage({
            fetchStatus: "loaded",
            code: status,
            type: "success",
          });
        } else {
          setPageMessage({
            fetchStatus: "loaded",
            code: status,
            type: "error",
            error: data.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setPageMessage({
          type: "error",
          text: "Couldn't load the document",
          fetchStatus: "loaded",
          code: 500,
        });
      });
  }, [username]);

  useEffect(() => {
    setPageMessage({
      fetchStatus: "loading",
    });
    getUserDocuments(username)
      .then(({ data, status }) => {
        if (status === 200) {
          setDocs(data);
          setPageMessage({
            fetchStatus: "loaded",
            code: status,
            type: "success",
          });
        } else {
          setPageMessage({
            fetchStatus: "loaded",
            code: status,
            type: "error",
            error: data.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setPageMessage({
          type: "error",
          text: "Couldn't load the document",
          fetchStatus: "loaded",
          code: 500,
        });
      });
  }, [username]);

  return (
    <Page
      title={visitee.name || "User"}
      pageMessage={pageMessage}
      classNameSuffix={"user-home"}
    >
      {docs &&
        docs.map(({ id, content, slug, created_at }) => (
          <a
            href={`/${visitee.username}/${slug}`}
            target="_blank"
            rel="noreferrer"
          >
            <Card key={id} classNameSuffix={"doc"}>
              <div className="doc-card-options">
                <a
                  href={`/${visitee.username}/${slug}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  /{slug}
                  <OpenInNewIcon />
                </a>
                <span className="created-date">{dateString(created_at)}</span>
              </div>
              <Preview value={content} />
            </Card>
          </a>
        ))}
    </Page>
  );
}
