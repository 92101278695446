import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeSlug from "rehype-slug";
import rehypeAutolinkHeadings from "rehype-autolink-headings";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  base16AteliersulphurpoolLight,
  vscDarkPlus,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import rehypeRaw from "rehype-raw";
import "./Preview.scss";
import { ReactComponent as CopyIcon } from "../../icons/ContentCopy.svg";
import { ReactComponent as DoneIcon } from "../../icons/Done.svg";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

function CodeCopy({ content }) {
  const [copied, setCopied] = useState(false);
  const copytoClipboard = function (content) {
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div className="code-copy" onClick={(_) => copytoClipboard(content)}>
      {copied ? (
        <DoneIcon className="clickable" />
      ) : (
        <CopyIcon className="clickable" />
      )}
    </div>
  );
}

export default function Preview({
  value,
  theme,
  header,
  previewContainerRef,
  scrollToId,
}) {
  const location = useLocation();
  const styleProp =
    theme === "light"
      ? { style: base16AteliersulphurpoolLight }
      : { style: vscDarkPlus };
  const customStyleAux =
    theme === "light" ? { backgroundColor: "#F5F8FA", border: "none" } : {};
  useEffect(() => {
    if (scrollToId || location.hash) {
      setTimeout(() => {
        const ele = document.getElementById(
          scrollToId || location.hash.split("#")[1]
        );
        window.scrollTo({ top: ele?.offsetTop - 50 });
      }, 100);
    }
  }, [value, scrollToId]);
  return (
    <div className="preview-container" ref={previewContainerRef}>
      <div className="preview">
        {header}
        <ReactMarkdown
          children={value}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[
            rehypeRaw,
            rehypeSlug,
            () =>
              rehypeAutolinkHeadings({
                behavior: "wrap",
                properties: { class: "generated-heading-autolink" },
              }),
          ]}
          sourcePos={true}
          // rawSourcePos={true}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              const childrenCode = String(children).replace(/\n$/, "");
              return !inline ? (
                <div className="block-code-parent">
                  <SyntaxHighlighter
                    children={childrenCode}
                    language={match ? match[1] : "text"}
                    PreTag="div"
                    customStyle={{
                      padding: "10px",
                      borderRadius: "5px",
                      fontSize: "85%",
                      lineHeight: 1.4,
                      ...customStyleAux,
                    }}
                    {...styleProp}
                    {...props}
                  />
                  <CodeCopy
                    className="clickable copy-icon"
                    content={childrenCode}
                  />
                </div>
              ) : (
                // <code className={className} {...props}>
                //   {children}
                // </code>
                <SyntaxHighlighter
                  children={children}
                  customStyle={{
                    display: "inline",
                    padding: "2px",
                    borderRadius: "1px",
                    ...customStyleAux,
                  }}
                  language="text"
                  PreTag="span"
                  {...styleProp}
                  {...props}
                />
              );
            },
          }}
        />
      </div>
    </div>
  );
}
