import MonacoEditor from "@monaco-editor/react";
import { useRef, useState } from "react";
import { ReactComponent as AddIcon } from "../../icons/AddCircle.svg";
import Modal from "../Modal";
import CheatSheetContent from "../../pages/CheatSheet/CheatSheetContent";
import "./Editor.scss";

export default function Editor({
  value,
  onChange,
  theme = "light",
  showSnippetOptions,
  onSnippetOptionsClosed = () => {},
  onCurrentLineChange = (lineNum, column, lineHeight) => {},
  onScrollChange = (lineNum, lineHeight) => {},
}) {
  const [cursorPosition, setCursorPosition] = useState(-1);
  const [currentTopScroll, setCurrentTopScroll] = useState(0);
  const [showSnippetModal, setShowSnippetModal] = useState(false);
  const [linePosition, setLinePosition] = useState({});
  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    editorRef.current.onDidChangeCursorPosition((e) => {
      onCurrentLineChange(e.position.lineNumber, e.position.column, 18);
      setLinePosition(e.position);
    });
    editorRef.current.onDidScrollChange((e) => {
      onScrollChange(parseInt(e ? e.scrollTop / 18 : 0) + 2, 18);
      setCurrentTopScroll(e ? e.scrollTop : 0);
    });
    editorRef.current.onMouseDown((e) => {
      setCursorPosition(
        Math.max(
          e.target.element.offsetTop,
          e.target.element.offsetParent.offsetTop
        ) + 18
      );
    });
  }

  const insertSnippet = (snippet) => {
    let lines = value.split("\n");
    let currentLine = lines[linePosition.lineNumber - 1];
    let p1 = currentLine ? currentLine.slice(0, linePosition.column - 1) : "";
    let p2 = currentLine ? currentLine.slice(linePosition.column - 1) : "";
    lines[linePosition.lineNumber - 1] = p1 + snippet + p2;
    onChange(lines.join("\n"));
    setShowSnippetModal(false);
    onSnippetOptionsClosed();
  };

  const addSnippetPosition = cursorPosition - currentTopScroll;

  return (
    <div className="editor">
      <MonacoEditor
        defaultLanguage="markdown"
        value={value}
        onChange={onChange}
        theme={theme === "light" ? "light" : "vs-dark"}
        options={{
          scrollbar: false,
          verticalScrollbarSize: "0px",
          wordWrap: true,
        }}
        onMount={handleEditorDidMount}
      />
      <Modal
        header="Insert snippet"
        show={showSnippetModal || showSnippetOptions}
        onClose={(_) => {
          setShowSnippetModal(false);
          onSnippetOptionsClosed();
        }}
      >
        <CheatSheetContent
          theme={theme}
          snippetActionText={"Insert"}
          onSnippetAction={insertSnippet}
        />
      </Modal>
      <AddIcon
        className="clickable add-snippet-icon"
        onClick={(_) => setShowSnippetModal(true)}
        style={
          addSnippetPosition > -1
            ? { top: addSnippetPosition }
            : { display: "none" }
        }
      />
    </div>
  );
}
