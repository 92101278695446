import { genClassName } from "../../utils";
import "./Card.scss";

export default function Card({
  children,
  classNameSuffix,
  className,
  headingLevel = "h3",
  title,
}) {
  const validHeadingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"];
  const safeHeading = headingLevel ? headingLevel.toLowerCase() : "";
  const Title = validHeadingLevels.includes(safeHeading) ? safeHeading : "p";
  return (
    <div className={genClassName("card", classNameSuffix, className)}>
      {title && (
        <Title className={genClassName("card-title", classNameSuffix)}>
          {title}
        </Title>
      )}
      <div className={genClassName("card-content", classNameSuffix)}>
        {children}
      </div>
    </div>
  );
}
