import "./Preview.scss";
import Preview from "../../components/Preview";
import { useParams, useLocation, Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getDocumentForSlug, getUser, getDocumentTreeForSlug } from "../../api";
import { dateString, getLocalDocument, getPathDetails } from "../../utils";
import NotificationPopup from "../../components/NotificationPopup";
import { ReactComponent as EditIcon } from "../../icons/Edit.svg";
import { ReactComponent as ListIcon } from "../../icons/List.svg";
import Page from "../../components/Page";

function FolderOutline({ docTree, username, slug }) {
  return (
    <div
      className={"folder-outline " + (slug === docTree?.slug ? "active" : "")}
    >
      <Link
        to={`/${username}/${docTree?.slug}`}
        className={slug === docTree?.slug ? "active" : ""}
      >
        {docTree?.title || docTree?.name}
      </Link>
      {docTree.type === "folder" &&
        docTree?.children?.map((item) => (
          <FolderOutline
            key={item.id}
            docTree={item}
            username={username}
            slug={slug}
          />
        ))}
    </div>
  );
}

export default function PreviewPage({ user, themeType }) {
  let location = useLocation();
  const { slug, username } = useParams();
  const [doc, setDoc] = useState();
  const [pageMessage, setPageMessage] = useState({});
  const [visitee, setVisitee] = useState({ name: "", username: "", email: "" });
  const [documentOutline, setDocumentOutline] = useState([]);
  const [docTree, setDocTree] = useState({});
  const [headerInView, setHeaderInView] = useState(true);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const [
    showPrivateVisibilityNotification,
    setShowPrivateVisibilityNotification,
  ] = useState(false);
  const [scrollToId, setScrollToId] = useState();

  const [currentFocusedNode, setCurrentFocusedNode] = useState(0);

  const fetchLocal = location.pathname.includes("/local/preview");

  const previewContainerRef = useRef();

  useEffect(() => {
    if (fetchLocal) {
      setDoc(getLocalDocument());
      setPageMessage({
        type: "custom-notification",
        notificationProps: {
          type: "neutral",
          autoDismiss: false,
          text: "This preview page is rendered for a locally available document. Please login and save it on the cloud to make it publicly visible.",
        },
      });
      return;
    }
    setPageMessage({
      fetchStatus: "loading",
    });
    getDocumentForSlug(username, slug)
      .then(({ data, status }) => {
        if (status === 200) {
          setDoc(data);
          setPageMessage({
            fetchStatus: "loaded",
            code: status,
            type: "success",
          });

          if (data.private) {
            setShowPrivateVisibilityNotification(true);
          }
        } else {
          setDoc(null);
          setPageMessage({
            fetchStatus: "loaded",
            code: status,
            type: "error",
            error: data.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setPageMessage({
          type: "error",
          text: "Couldn't load the document",
          fetchStatus: "loaded",
          code: 500,
        });
      });

    getDocumentTreeForSlug(username, slug)
      .then(({ data, status }) => {
        if (status === 200) {
          // setDoc(data);
          setDocTree(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [slug, username, fetchLocal]);

  useEffect(() => {
    if (!username) {
      if (fetchLocal) {
        setVisitee({
          username: user ? user.username : "new-doc",
          name: user ? user.name : "Anonymous",
        });
      }
      return;
    }
    setPageMessage({
      fetchStatus: "loading",
    });
    getUser(username)
      .then(({ data, status }) => {
        if (status === 200) {
          setVisitee(data);
          setPageMessage({
            fetchStatus: "loaded",
            code: status,
            type: "success",
          });
        } else {
          setPageMessage({
            fetchStatus: "loaded",
            code: status,
            type: "error",
            error: data.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setPageMessage({
          type: "error",
          text: "Couldn't load the document",
          fetchStatus: "loaded",
          code: 500,
        });
      });
  }, [user, username, fetchLocal]);

  useEffect(() => {
    if (doc) {
      setTimeout(() => {
        const headings = document.querySelectorAll(
          "h2 a.generated-heading-autolink"
        );
        let outline = [];
        headings.forEach((node) =>
          outline.push({
            link: node.hash,
            text: node.innerText,
            offsetTop: node.offsetTop,
          })
        );
        setDocumentOutline(outline);
      }, 100);
    } else {
      setDocumentOutline([]);
    }
  }, [doc]);

  useEffect(() => {
    const headerNode = document?.getElementsByClassName("header")[0];
    const handleScroll = (e) => {
      const current = window.pageYOffset;
      const headerHeight = (
        headerNode || document?.getElementsByClassName("header")[0]
      ).offsetHeight;
      const unscrolledNodes = documentOutline.filter(
        (node) => node.offsetTop > current
      );
      setCurrentFocusedNode(
        unscrolledNodes.length > 0
          ? unscrolledNodes[0]
          : documentOutline[documentOutline.length - 1]
      );
      setHeaderInView(current < headerHeight);
    };

    setTimeout(() => {
      window.addEventListener("scroll", handleScroll);
    }, 1000);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [documentOutline]);

  const pathDetails = getPathDetails(
    { id: "root", children: [docTree] },
    doc?.id
  );

  const docEditPath =
    doc?.type === "folder"
      ? `/me/docs/${doc?.id}`
      : `/me/docs/${doc?.id}/edit${
          pathDetails?.length > 2
            ? "?path=" +
              pathDetails
                ?.slice(0, pathDetails.length - 1)
                ?.map((p) => p.id)
                ?.join("-")
            : ""
        }`;

  return (
    <Page
      pageMessage={pageMessage}
      classNameSuffix={"preview"}
      title={doc && doc.title}
      displayTitle={false}
      className={docTree.type === "folder" ? "folder" : "no-folder"}
      noPadding
    >
      {showPrivateVisibilityNotification && (
        <NotificationPopup
          type="neutral"
          text="This is a private document and is only visible to you. Make it public to share it with everyone."
          onDismiss={(_) => setShowPrivateVisibilityNotification(false)}
        />
      )}

      <div
        className={
          "published-content " +
          (docTree.type !== "folder" || headerInView
            ? ""
            : "show-folder-header")
        }
      >
        <div
          className={
            "folder-outline-container " +
            (docTree.type === "folder" && showLeftBar ? "show" : "hide")
          }
        >
          {docTree.type === "folder" && (
            <div className="folder-header">
              <ListIcon
                className="list-icon clickable"
                onClick={(_) => setShowLeftBar(!showLeftBar)}
              />
              <Link to={`/${username}`} className="folder-header-username-link">
                {visitee.name}
              </Link>
              /
              <Link
                to={`/${username}/${docTree.slug}`}
                className="folder-header-folder-link"
              >
                {docTree.name}
              </Link>
            </div>
          )}
          {docTree.id !== "root" && docTree.type === "folder" && (
            <FolderOutline docTree={docTree} username={username} slug={slug} />
          )}
        </div>
        <Preview
          value={doc && doc.content}
          theme={themeType}
          scrollToId={scrollToId}
          header={
            <div className="doc-header">
              <div className="doc-info">
                {doc && <a href={`/${visitee.username}`}>{visitee.name}</a>}
                <span className="created-date">
                  {dateString(doc && doc.created_at)}
                </span>
              </div>
              {user?.username === username && (
                <Link to={docEditPath}>
                  <EditIcon className="edit-icon clickable" />
                </Link>
              )}
            </div>
          }
          previewContainerRef={previewContainerRef}
        />
        <div className="document-outline-container">
          <div className="document-outline">
            {documentOutline.length > 0 && (
              <p className="document-outline-title">ON THIS PAGE</p>
            )}
            {documentOutline.map((item) => (
              <a
                className={
                  "document-outline-item clickable " +
                  (item.link === currentFocusedNode?.link
                    ? "document-outline-item-active"
                    : "")
                }
                key={item.link}
                onClick={(_) => {
                  window.location.hash = item.link;
                  setScrollToId(item.link.split("#")[1]);
                }}
              >
                {item.text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
}
