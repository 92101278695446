import "./Modal.scss";
import { ReactComponent as Close } from "../../icons/Close.svg";

export default function Modal({
  show,
  onClose,
  header,
  footer,
  children,
  headingLevel = "h3",
}) {
  const validHeadingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"];
  const safeHeading = headingLevel ? headingLevel.toLowerCase() : "";
  const Header = validHeadingLevels.includes(safeHeading) ? safeHeading : "p";
  if (!show) {
    return "";
  }
  return (
    <div className={`modal-container ${footer ? "" : "empty-footer"}`}>
      <div className="modal">
        <div className="modal-header-container">
          <Header className={`modal-header ${!header && "modal-header-empty"}`}>
            {header}
          </Header>
          <Close className="clickable" onClick={onClose || (() => {})} />
        </div>
        <div className="modal-content">{children}</div>
        <div className="modal-footer">{footer}</div>
      </div>
    </div>
  );
}
