import { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import "./NotificationPopup.scss";

export default function NotificationPopup({
  title,
  text,
  type = "neutral",
  autoDismiss = true,
  onDismiss = () => {},
}) {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setShow(true);
    if (autoDismiss) {
      setTimeout(() => {
        setShow(false);
        onDismiss();
      }, 2000);
    }
  }, [title, text]);
  return (
    <div
      className={"notification-popup notification-popup-" + type}
      hidden={!show}
    >
      <CloseIcon
        className="clickable notification-popup-close"
        onClick={() => {
          setShow(false);
          onDismiss();
        }}
      />
      {title && <p className="notification-popup-title">{title}</p>}
      {text && <p className="notification-popup-text">{text}</p>}
    </div>
  );
}
