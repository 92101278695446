import Page from "../../components/Page";
import { createDocument, updateDocument, getDocument } from "../../api";
import "./AllDocuments.scss";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Card from "../../components/Card";
import { dateTimeString } from "../../utils";
import { ReactComponent as OpenInNewIcon } from "../../icons/OpenInNew.svg";
import { ReactComponent as NewDocIcon } from "../../icons/NewDoc.svg";
import { ReactComponent as NewFolderIcon } from "../../icons/NewFolder.svg";
import { ReactComponent as FolderIcon } from "../../icons/Folder.svg";
import { ReactComponent as EditIcon } from "../../icons/Edit.svg";
import { getLocalDocument, getPathDetails } from "../../utils";
import Modal from "../../components/Modal";
import PathRoute from "../../components/PathRoute";

function DocumentCard({
  name,
  slug,
  content,
  content_short,
  created_at,
  updated_at,
  id,
  username,
  type,
  pathDetails,
}) {
  const cardId = name ? name.replaceAll(" ", "-").toLowerCase() : "-";
  const link = id
    ? type === "folder"
      ? `/me/docs/${id}`
      : `/me/docs/${id}/edit?path=${pathDetails.map((p) => p.id).join("-")}`
    : "/new-doc";
  const publishedLink = username
    ? `/${username || "-"}/${slug}`
    : `/local/preview/${slug || "you-slug-here"}`;
  return (
    <Card
      className={"document-card"}
      classNameSuffix={cardId}
      title={
        <Link to={link}>
          <div className="document-card-header">
            <span className="document-card-header-title">{name}</span>
            {type !== "folder" && <EditIcon className="edit-icon" />}
          </div>
        </Link>
      }
    >
      <p className="document-card-slug">
        <Link to={publishedLink}>/{slug || "you-slug-here"}</Link>
        <a href={publishedLink} target="_blank" rel="noreferrer">
          <OpenInNewIcon />
        </a>
      </p>
      <Link to={link}>
        {type === "folder" ? (
          <FolderIcon className="folder-icon document-card-preview" />
        ) : (
          <p className="document-card-preview">{content_short || content}</p>
        )}
      </Link>

      {updated_at && (
        <div className="document-card-timestamp">
          <span className="document-card-timestamp-label">Last Updated: </span>
          <span className="document-card-timestamp-value">
            {dateTimeString(updated_at)}
          </span>
        </div>
      )}

      {created_at && (
        <div className="document-card-timestamp">
          <span className="document-card-timestamp-label">Created: </span>
          <span className="document-card-timestamp-value">
            {dateTimeString(created_at)}
          </span>
        </div>
      )}
    </Card>
  );
}

export default function AllDocuments({
  showAuth,
  user,
  docsTree,
  fetchDocTree,
}) {
  const [pageMessage, setPageMessage] = useState({});
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [newFolderDescription, setNewFolderDescription] = useState("");
  const [newFolderSlug, setNewFolderSlug] = useState("");
  const [newFolderError, setNewFolderError] = useState("");
  const [updatedSlug, setUpdatedSlug] = useState(false);
  const [pathDetails, setPathDetails] = useState(["root"]);
  const [currentFolderDetails, setCurrentFolderDetails] = useState({});
  const [oldCurrentFolderState, setOldCurrentFolderState] = useState({});
  const [pageTitle, setPageTitle] = useState("");
  const localDoc = getLocalDocument();
  const { folderId } = useParams();

  useEffect(() => {
    const pathDetails = getPathDetails(docsTree, folderId);
    if (pathDetails) {
      setPathDetails(pathDetails);
      const currentFolder = pathDetails[pathDetails.length - 1];
      if (currentFolder.type !== "folder" && currentFolder.id !== "root") {
        window.location = `/me/docs/${currentFolder.id}/edit`;
      }
      setCurrentFolderDetails({
        name: currentFolder.name,
        slug: currentFolder.slug,
        content: currentFolder.content,
      });
      setPageTitle(
        currentFolder.id === "root" ? "All Documents" : currentFolder.name
      );
      setOldCurrentFolderState(currentFolder);
      folderId &&
        getDocument(folderId)
          .then(({ data, status }) => {
            const doc = data;
            if (status === 200) {
              setOldCurrentFolderState(Object.assign({}, currentFolder, doc));
              setCurrentFolderDetails(doc);
              setPageMessage({
                fetchStatus: "loaded",
                code: 200,
              });
              setPageTitle(doc.id === "root" ? "All Documents" : doc.name);
              return;
            }
            let msg = {
              code: status,
              fetchStatus: "loaded",
              type: "error",
            };
            if (status !== 403 && status !== 401) {
              msg.text = data.error;
            } else {
              showAuth();
            }
            setPageMessage(msg);
          })
          .catch((e) => {
            console.error(e);
            setPageMessage({
              type: "error",
              text: "Couldn't load the document",
              fetchStatus: "loaded",
              code: 500,
            });
          });
    }
  }, [folderId, docsTree]);

  const showLocalDoc =
    !user &&
    localDoc &&
    (localDoc.name !== "" || localDoc.content !== "# Header");

  const createFolder = () => {
    createDocument(
      {
        name: newFolderName,
        content: newFolderDescription,
        slug: newFolderSlug,
        type: "folder",
        private: true,
      },
      folderId
    )
      .then(({ data, status }) => {
        if (status === 200) {
          window.location = `/me/docs/${data.id}`;
        } else {
          if (status === 403 || status === 401) {
            showAuth();
          }
          setNewFolderError(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
        setNewFolderError("Could not create a new folder");
      });
  };

  const getUnsavedData = () => {
    let changes = {};

    if (currentFolderDetails.slug === "") {
      return {};
    }
    if (currentFolderDetails.name !== oldCurrentFolderState.name) {
      changes["name"] = currentFolderDetails.name;
    }
    if (currentFolderDetails.slug !== oldCurrentFolderState.slug) {
      changes["slug"] = currentFolderDetails.slug;
    }
    if (currentFolderDetails.content !== oldCurrentFolderState.content) {
      changes["content"] = currentFolderDetails.content;
    }
    if (currentFolderDetails.private !== oldCurrentFolderState.private) {
      changes["private"] = currentFolderDetails.private;
    }
    return changes;
  };

  const updateFolderDetails = () => {
    updateDocument(folderId, Object.assign({}, getUnsavedData()))
      .then(({ data, status }) => {
        const doc = data;
        if (status === 200) {
          setCurrentFolderDetails({
            name: doc.name,
            slug: doc.slug,
            content: doc.content,
            private: doc.private,
          });
          setPageMessage({
            fetchStatus: "saveSuccess",
            type: "success",
            code: status,
          });
          fetchDocTree();
        } else {
          let msg = {
            fetchStatus: "saveFailed",
            type: "error",
            code: status,
          };
          if (status === 403 || status === 401) {
            showAuth();
          } else {
            msg.text = doc.error;
          }
          setPageMessage(msg);
        }
      })
      .catch((error) => {
        console.error(error);
        setPageMessage({
          fetchStatus: "saveFailed",
          type: "error",
          text: "Couldn't save the document",
          code: 500,
        });
      });
  };

  const docs = oldCurrentFolderState?.children || [];

  const publishedPath =
    user && user.username && oldCurrentFolderState.id
      ? `/${user.username || "<username>"}/${
          oldCurrentFolderState.slug || "your-slug-here"
        }`
      : `/local/preview/${oldCurrentFolderState.slug || "your-slug-here"}`;

  return (
    <Page
      title="All Documents"
      className="all-documents"
      classNameSuffix={oldCurrentFolderState?.id !== "root" && "nested"}
      headerActions={
        <>
          <NewFolderIcon
            className="folder clickable"
            onClick={(_) => setShowFolderModal(true)}
          />
          <Link to={`/new-doc?path=${pathDetails.map((p) => p.id).join("-")}`}>
            <NewDocIcon />
          </Link>
        </>
      }
      pageMessage={pageMessage}
    >
      <PathRoute pathDetails={pathDetails} user={user} />
      <Modal
        show={showFolderModal}
        onClose={(_) => setShowFolderModal(false)}
        header="Create folder"
      >
        {
          "Folder appears as a document collection on any of its documents' published page"
        }
        <br />
        <br />
        <p style={{ color: "red" }}>{newFolderError}</p>
        <p>Folder Name</p>
        <input
          value={newFolderName}
          required
          onChange={(e) => {
            setNewFolderName(e.target.value);
            if (!updatedSlug) {
              setNewFolderSlug(
                e.target.value?.toLowerCase()?.replaceAll(/[^-.~\w]/g, "-")
              );
            }
          }}
          placeholder="Folder name"
        />
        <p>Folder Slug</p>
        <span>
          /
          <input
            required
            value={newFolderSlug}
            onChange={(e) => {
              setNewFolderSlug(e.target.value.replaceAll(/[^-.~\w]/g, "-"));
              setUpdatedSlug(true);
            }}
            placeholder="folder-url-slug"
          />
        </span>

        <p>Folder Description (markdown compatible)</p>
        <textarea
          required
          value={newFolderDescription}
          onChange={(e) => setNewFolderDescription(e.target.value)}
          placeholder="### You can write in markdown here"
        />
        <br />
        <button onClick={createFolder}>Create</button>
      </Modal>
      <div className="documents-list-container">
        {pathDetails?.length > 1 && (
          <div className="folder-info">
            <p>Folder Name</p>
            <input
              value={currentFolderDetails.name}
              required
              onChange={(e) => {
                setCurrentFolderDetails({
                  ...currentFolderDetails,
                  name: e.target.value,
                });
              }}
              placeholder="Folder name"
            />
            <p>Folder Slug</p>
            <span>
              /
              <input
                required
                value={currentFolderDetails.slug}
                onChange={(e) => {
                  setCurrentFolderDetails({
                    ...currentFolderDetails,
                    slug: e.target.value.replaceAll(/[^-.~\w]/g, "-"),
                  });
                }}
                placeholder="folder-url-slug"
              />
            </span>

            <p>Folder Description (markdown compatible)</p>
            <textarea
              required
              value={currentFolderDetails.content}
              onChange={(e) =>
                setCurrentFolderDetails({
                  ...currentFolderDetails,
                  content: e.target.value,
                })
              }
              placeholder="### You can write in markdown here"
            />
            <br />
            <button
              disabled={Object.keys(getUnsavedData()).length === 0}
              onClick={updateFolderDetails}
            >
              Save
            </button>
          </div>
        )}
        {showLocalDoc && (
          <div>
            <h3 style={{ paddingLeft: "20px" }}>Unsaved Document</h3>
            <div className="documents-list">
              <DocumentCard {...localDoc} />
            </div>
          </div>
        )}
        <div className="documents-list">
          {docs.map((doc) => (
            <DocumentCard
              {...doc}
              key={doc.id}
              username={user.username}
              pathDetails={pathDetails}
            />
          ))}
        </div>
      </div>
    </Page>
  );
}
