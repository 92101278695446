import "./App.scss";
import Header from "./components/Header";
import Editor from "./pages/Editor";
import Modal from "./components/Modal";
import { useEffect, useState } from "react";
import {
  signup,
  login,
  getCurrentUser,
  googleAuth,
  updateCurrentUser,
  getRootDocsTree,
} from "./api";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AllDocuments from "./pages/AllDocuments";
import Preview from "./pages/Preview";
import ThemeStyle from "./theme.json";
import NotificationPopup from "./components/NotificationPopup";
import UserHome from "./pages/UserHome";
import CheatSheet from "./pages/CheatSheet";

function LoginForm({ setUserDetails, showSignupForm }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const loginUser = () => {
    login({ username: username, password: password })
      .then(({ data }) => {
        if (!data.success || !data.user) {
          setError("Login failed!");
        } else {
          setError("");
          setUserDetails(data.user);
        }
      })
      .catch((e) => {
        console.error(e);
        setError("Login failed!");
      });
  };

  return (
    <div className="auth-modal-content">
      <p>
        No existing account?{" "}
        <button className="plain" onClick={showSignupForm}>
          Sign Up
        </button>
      </p>
      <span style={{ color: "red" }}>{error}</span>
      <br />
      <input
        value={username}
        type="email"
        placeholder="Username or VERIFIED email"
        onChange={(e) => setUsername(e.target.value)}
        required
      />
      <br />
      <input
        value={password}
        type="password"
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <br />
      <button
        className="auth-action"
        onClick={loginUser}
        disabled={!username || !password}
      >
        Login
      </button>
    </div>
  );
}

function SignupForm({ showLoginForm }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const signupUser = () => {
    signup({ username: username, password: password, email: email, name: name })
      .then(({ data }) => {
        if (data.error) {
          setError("Signup failed: " + data.error);
          setSuccessMessage("");
        } else {
          setError("");
          setSuccessMessage(
            "Signup successful. You will receive a verification email (check spam folder too)"
          );
        }
      })
      .catch((e) => {
        console.error(e);
        setError("Signup failed: " + e);
        setSuccessMessage("");
      });
  };

  const validateData = function () {
    return (
      username.length > 5 &&
      name.length > 2 &&
      validateEmail(email) &&
      password.length > 5
    );
  };

  return (
    <div className="auth-modal-content">
      <p>
        Existing user?{" "}
        <button className="plain" onClick={showLoginForm}>
          Login
        </button>
      </p>

      <span style={{ color: "red" }}>{error}</span>
      <span style={{ color: "green" }}>{successMessage}</span>
      <br />
      <input
        value={name}
        placeholder="Name (minimum 3 characters)"
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <input
        value={username}
        placeholder="Username (minimum 6 characters)"
        onChange={(e) =>
          setUsername(e.target.value.replaceAll(/[^-.~\w]/g, "-"))
        }
      />
      <br />
      <input
        value={email}
        type="email"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <input
        value={password}
        type="password"
        placeholder="Password (minimum 6 characters)"
        onChange={(e) => setPassword(e.target.value)}
      />
      <br />
      <button
        className="auth-action"
        onClick={signupUser}
        disabled={!validateData()}
      >
        Signup
      </button>
    </div>
  );
}

function AuthModal({ show, onClose, setUserDetails }) {
  const [showLogin, setShowLogin] = useState(false); // Show login when true and signup when false

  useEffect(() => {
    window.google?.accounts?.id?.renderButton(
      document.getElementById("google-signin"),
      { theme: "outline", size: "large" } // customization attributes
    );
  }, [show]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      header={showLogin ? "Login" : "Signup"}
    >
      <div className="auth-modal-content">
        For saving document to server and accessing other account based
        features.
        <div id="google-signin" />
        ----- OR -----
      </div>
      {showLogin ? (
        <LoginForm
          setUserDetails={setUserDetails}
          showSignupForm={(e) => setShowLogin(false)}
        />
      ) : (
        <SignupForm showLoginForm={(e) => setShowLogin(true)} />
      )}
      <div className="auth-modal-content"></div>
    </Modal>
  );
}

function UserProfileForm({ userDetails, setUserDetails, show }) {
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setUsername(userDetails?.username || "");
    setName(userDetails?.name || "");
  }, [userDetails]);

  const updateUser = () => {
    updateCurrentUser({ username: username, name: name })
      .then(({ data, status }) => {
        if (status !== 200) {
          setError("Update failed: " + data.error);
        } else {
          setError("");
          setUserDetails(data);
        }
      })
      .catch((e) => {
        console.error(e);
        setError("Update failed: " + e);
      });
  };

  const validateData = function () {
    return username.length > 5 && name.length > 2;
  };

  return (
    <Modal show={show} header={"Mandatory Profile Details"}>
      <div className="auth-modal-content">
        <span style={{ color: "red" }}>{error}</span>
        <br />
        <input
          value={name}
          placeholder="Name (minimum 3 characters)"
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <input
          value={username}
          placeholder="Username (minimum 6 characters)"
          onChange={(e) =>
            setUsername(e.target.value.replaceAll(/[^-.~\w]/g, "-"))
          }
        />
        <br />
        <button
          className="auth-action"
          onClick={updateUser}
          disabled={!validateData()}
        >
          Update
        </button>
      </div>
    </Modal>
  );
}

function setThemeTypeCookie(theme) {
  document.cookie = `themeType=${theme};path=/`;
}

function getThemeTypeCookie() {
  let themeTypeCookieString = document.cookie
    .split("; ")
    .find((row) => row.startsWith("themeType="));
  return themeTypeCookieString && themeTypeCookieString.split("=")[1];
}

function App() {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [themeType, setThemeType] = useState("light");
  const [showDefaultThemePopup, setShowDefaultThemePopup] = useState(false);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [docsTree, setDocsTree] = useState({ id: "root", children: [] });

  const saveThemeType = (selectedTheme) => {
    setThemeTypeCookie(selectedTheme);
    setThemeType(selectedTheme);
  };

  useEffect(() => {
    const savedTheme = getThemeTypeCookie();
    if (savedTheme) {
      setThemeType(savedTheme);
    } else {
      setThemeType("light");
      setThemeTypeCookie("light");
      setShowDefaultThemePopup(true);
    }
  });

  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    window.google?.accounts?.id?.initialize({
      client_id: googleClientId,
      callback: handleCredentialResponse,
    });
  }, []);

  const fetchDocTree = () => {
    getRootDocsTree()
      .then(({ data }) => {
        if (!data.error) {
          setDocsTree(data);
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    getCurrentUser()
      .then(({ data }) => {
        if (!data.error) {
          setUserDetails(data);
        } else {
          setUserDetails(null);
          window.google?.accounts?.id?.initialize({
            client_id: googleClientId,
            callback: handleCredentialResponse,
          });
          window.google?.accounts?.id?.prompt(); // also display the One Tap dialog
        }
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    fetchDocTree();
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.email && !userDetails.username) {
      setShowUserProfileModal(true);
    }
  }, [userDetails]);

  const handleGoogleAuth = (token) => {
    googleAuth(token)
      .then(({ data }) => {
        if (!data.success || !data.user) {
        } else {
          setUserDetails(data.user);
          setShowAuthModal(false);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  function handleCredentialResponse(response) {
    handleGoogleAuth(response.credential);
  }

  const setShowAuthModalTrue = () => setShowAuthModal(true);

  return (
    <div className="App" style={ThemeStyle[themeType]}>
      {showDefaultThemePopup && (
        <div className="theme-popup">
          <NotificationPopup
            title={"Theme"}
            text={
              "Default theme is set as light. Switch to dark mode for dark theme."
            }
            autoDismiss={false}
          />
        </div>
      )}
      <BrowserRouter>
        <Header
          user={userDetails}
          showAuth={setShowAuthModalTrue}
          themeType={themeType}
          onThemeTypeChange={saveThemeType}
        />
        <AuthModal
          show={showAuthModal}
          onClose={() => setShowAuthModal(false)}
          setUserDetails={(details) => {
            setUserDetails(details);
            setShowAuthModal(false);
          }}
        />
        <UserProfileForm
          show={showUserProfileModal}
          userDetails={userDetails}
          // onClose={() => setShowUserProfileModal(false)}
          setUserDetails={(details) => {
            setUserDetails(details);
            setShowUserProfileModal(false);
          }}
        />
        <Routes>
          <Route index element={<Navigate to="/new-doc" replace={true} />} />
          <Route
            path="/new-doc"
            element={
              <Editor
                user={userDetails}
                themeType={themeType}
                showAuth={setShowAuthModalTrue}
                docsTree={docsTree}
                fetchDocTree={fetchDocTree}
              />
            }
          />
          <Route
            path="/me/docs"
            element={
              <AllDocuments
                user={userDetails}
                themeType={themeType}
                showAuth={setShowAuthModalTrue}
                docsTree={docsTree}
                fetchDocTree={fetchDocTree}
              />
            }
          />
          <Route
            path="/me/docs/:folderId"
            element={
              <AllDocuments
                user={userDetails}
                themeType={themeType}
                showAuth={setShowAuthModalTrue}
                docsTree={docsTree}
                fetchDocTree={fetchDocTree}
              />
            }
          />
          <Route
            path="/appdocs/markdown-cheatsheet"
            element={
              <CheatSheet
                user={userDetails}
                themeType={themeType}
                showAuth={setShowAuthModalTrue}
                docsTree={docsTree}
                fetchDocTree={fetchDocTree}
              />
            }
          />
          <Route
            path="/:username"
            element={
              <UserHome
                user={userDetails}
                themeType={themeType}
                showAuth={setShowAuthModalTrue}
                docsTree={docsTree}
                fetchDocTree={fetchDocTree}
              />
            }
          />
          <Route
            path="/me/docs/:docId/edit"
            element={
              <Editor
                themeType={themeType}
                user={userDetails}
                showAuth={setShowAuthModalTrue}
                docsTree={docsTree}
                fetchDocTree={fetchDocTree}
              />
            }
          />
          <Route
            path="/:username/:slug"
            element={
              <Preview
                themeType={themeType}
                user={userDetails}
                showAuth={setShowAuthModalTrue}
                docsTree={docsTree}
                fetchDocTree={fetchDocTree}
              />
            }
          />
          <Route
            path="/local/preview/:slug"
            element={
              <Preview
                themeType={themeType}
                user={userDetails}
                showAuth={setShowAuthModalTrue}
                docsTree={docsTree}
                fetchDocTree={fetchDocTree}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
