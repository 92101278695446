import { markdownBlocks } from "../../data";
import Preview from "../../components/Preview";
import "./CheatSheetContent.scss";
import { useState } from "react";

function SnippetBlocks({
  snippetBlock,
  index,
  theme,
  snippet,
  snippetActionText,
  onSnippetAction,
}) {
  return (
    <div className="snippet-blocks">
      <div>
        <button
          className="plain"
          onClick={(_) => onSnippetAction(snippet, snippetBlock)}
        >
          {snippetActionText}
        </button>
      </div>

      <div className="snippet-blocks-item">
        <div className="markdown-text">
          {snippetBlock === "Code Blocks" && index === 1 ? (
            <div className="block-code-snippet">
              Markdown:
              <br />
              <br />
              {snippet}
              <br />
              <br />
              Preview:
            </div>
          ) : (
            <Preview
              theme={theme}
              value={`Markdown:\n\`\`\`markdown\n${snippet}\n\`\`\`\nPreview:`}
            />
          )}
        </div>
        <div className="markdown-rendered">
          <Preview value={snippet + "\n\n"} theme={theme} />
        </div>
      </div>
    </div>
  );
}

export default function CheatSheetContent({
  theme,
  snippetActionText,
  onSnippetAction,
  initSnippet,
  onSnippetSelected = (_) => {},
}) {
  const [snippetBlock, setSnippetBlock] = useState(
    initSnippet || "Complete Guide"
  );
  const handleSnippetSelected = (snippet) => {
    setSnippetBlock(snippet);
    onSnippetSelected(snippet);
  };
  return (
    <div className="cheat-sheet-content">
      <div
        onClick={(_) => handleSnippetSelected("Complete Guide")}
        className={`clickable snippet-options-item complete-guide ${
          "Complete Guide" === snippetBlock ? "selected" : ""
        }`}
      >
        Complete Guide
      </div>
      <div className="snippet-options">
        {Object.keys(markdownBlocks).map((block) => (
          <div
            key={block}
            onClick={(_) => handleSnippetSelected(block)}
            className={`clickable snippet-options-item ${
              block === snippetBlock ? "selected" : ""
            }`}
          >
            {block}
          </div>
        ))}
      </div>
      {snippetBlock === "Complete Guide" &&
        Object.keys(markdownBlocks).map((block) => (
          <div key={block}>
            <br />
            <h2 style={{ margin: 0 }}>{block}</h2>
            {markdownBlocks[block].map((snippet, index) => (
              <SnippetBlocks
                key={block + "-" + index}
                index={index}
                snippetBlock={block}
                snippet={snippet}
                snippetActionText={snippetActionText}
                onSnippetAction={onSnippetAction}
                theme={theme}
              />
            ))}
          </div>
        ))}
      {(markdownBlocks[snippetBlock] || []).map((snippet, index) => (
        <SnippetBlocks
          key={snippetBlock + "-" + index}
          index={index}
          snippetBlock={snippetBlock}
          snippet={snippet}
          snippetActionText={snippetActionText}
          onSnippetAction={onSnippetAction}
          theme={theme}
        />
      ))}
    </div>
  );
}
