import "./PathRoute.scss";
import { Link } from "react-router-dom";
import { ReactComponent as OpenInNewIcon } from "../../icons/OpenInNew.svg";
import { ReactComponent as ArrowRightIcon } from "../../icons/ArrowRight.svg";

export default function PathRoute({ pathDetails, user }) {
  const currentFolder = pathDetails[pathDetails.length - 1];
  const publishedPath =
    user && user.username && currentFolder.id
      ? `/${user.username || "<username>"}/${
          currentFolder.slug || "your-slug-here"
        }`
      : `/local/preview/${currentFolder.slug || "your-slug-here"}`;
  return pathDetails?.length > 1 ? (
    <div className="path-route">
      {pathDetails?.map((path, index) =>
        index === pathDetails.length - 1 ? (
          <div
            key={path.id}
            className="path-route-row"
            style={{ marginLeft: (index - 1) * 10 + "px" }}
          >
            <br />
            <ArrowRightIcon />
            <span>{path.name}</span>
            <a
              className="folder-published-link"
              href={publishedPath}
              target="_blank"
              rel="noreferrer"
            >
              <OpenInNewIcon />
            </a>
          </div>
        ) : (
          <div
            key={path.id}
            className="path-route-row"
            style={{ marginLeft: (index > 0 ? index - 1 : 0) * 10 + "px" }}
          >
            {index > 0 && <ArrowRightIcon />}
            <Link to={path.id === "root" ? "/me/docs" : `/me/docs/${path.id}`}>
              {path.id === "root" ? "All Documents" : path.name}
            </Link>
          </div>
        )
      )}
    </div>
  ) : (
    ""
  );
}
