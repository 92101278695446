const horizontalLine = "---";

const heading1 = "# Heading 1";
const heading2 = "## Heading 2";
const heading3 = "### Heading 3";
const heading4 = "#### Heading 4";
const heading5 = "##### Heading 5";
const heading6 = "###### Heading 6";

const italics = "*Italics text*";
const bold = "**Bold text**";
const strikethrough = "~Strikethrough~";

const blockquote = "> This is a block quote!";

const checkboxList = `- [ ] Unchecked checkbox
- [x] Checked checkbox`;

const list = `- List
  - With
  - Sub items

   And indented paragraphs

   To have a line break without a paragraph, you will need to use two trailing spaces.

- Without
- Numbering`;

const numberedList = `1. List
2. With
3. Numbering`;

const links = "[About Markdit](https://www.markdit.com/appdocs/markdit)";

const table = `
Colons can be used to align columns.

| Tables        | Are           | Cool  |
| ------------- |:-------------:| -----:|
| col 3 is      | right-aligned | $1600 |
| col 2 is      | centered      |   $12 |
| zebra stripes | are neat      |    $1 |
|*Inline* | \`markdown\` | **works**|
`;

const inlineImage =
  "Inline image example. Markdit logo image. ![Inline](https://www.markdit.com/client/favicon.ico)";

const blockImage = `
Block image example.

![Markdit logo][MarkditLogo]

[MarkditLogo]: https://www.markdit.com/client/logo192.png
`;

const inlineCode = "Inline `code block` for writing inline code";

const blockCode = `\`\`\`javascript
// Code block for writing code snippets
const message = "This is a markdown editor";
alert(message);
\`\`\``;

export const markdownBlocks = {
  Headings: [heading1, heading2, heading3, heading4, heading5, heading6],
  "Horizontal line": [horizontalLine],
  Table: [table],
  "Font Styles": [italics, bold, strikethrough],
  "Block Quotes": [blockquote],
  "Checkbox List": [checkboxList],
  List: [list],
  "Numbered List": [numberedList],
  Links: [links],
  Images: [inlineImage, blockImage],
  "Code Blocks": [inlineCode, blockCode],
};

export function getMarkdownBlockText(block) {
  if (!block || !markdownBlocks[block]) return;
  let text = "### " + block + "\n\n";
  markdownBlocks[block].forEach((blockItem) => {
    text += "\n" + blockItem + "\n";
  });
  return text;
}

function generateNewMarkdownText() {
  let text = "# Markdown cheatsheet\n";
  Object.keys(markdownBlocks).forEach((k) => {
    text += "\n### " + k + "\n";
    markdownBlocks[k].forEach((block) => {
      text += "\n" + block + "\n";
    });
  });
  return text;
}

export const NewMarkdownText = "# Untitled\n\n"; //generateNewMarkdownText();
