import { useState, useEffect, useLayoutEffect, useRef } from "react";
import Editor from "../../components/Editor";
import Preview from "../../components/Preview";
import Page from "../../components/Page/Page";
import { ReactComponent as Close } from "../../icons/Close.svg";
import { ReactComponent as PreviewOn } from "../../icons/Preview.svg";
import { ReactComponent as OpenInNewIcon } from "../../icons/OpenInNew.svg";
import { ReactComponent as Public } from "../../icons/Public.svg";
import { ReactComponent as Private } from "../../icons/Private.svg";
import { ReactComponent as Zen } from "../../icons/Zen.svg";
import { ReactComponent as IntegrationInstructionsIcon } from "../../icons/IntegrationInstructions.svg";
import { getMarkdownBlockText } from "../../data";
import "./Editor.scss";
import {
  useLocation,
  Link,
  useParams,
  usePrompt,
  useSearchParams,
} from "react-router-dom";
import { getDocument, createDocument, updateDocument } from "../../api";
import Modal from "../../components/Modal";
import {
  getLocalDocument,
  setLocalDocument,
  getPathDetailsFromPath,
} from "../../utils";
import { NewMarkdownText } from "../../data";

function setPreviewCookie(val) {
  document.cookie = `mpreview=${val};path=/`;
}

function getPreviewCookie() {
  let previewCookieString = document.cookie
    .split("; ")
    .find((row) => row.startsWith("mpreview="));
  let stringVal = previewCookieString && previewCookieString.split("=")[1];
  return stringVal;
}

function computePreviewScrollTop(lineNum, previewContainerRef) {
  let currentDom = 0;
  for (
    let i = 0;
    i < previewContainerRef.current.children[0].children.length;
    i++
  ) {
    let dom = previewContainerRef.current.children[0].children[i];
    let line = parseInt((dom.dataset.sourcepos || "0").split(":")[0]);
    if (line < lineNum + 1) {
      currentDom = i;
    }
  }
  return (
    previewContainerRef.current.children[0].children[currentDom].offsetTop -
    previewContainerRef.current.children[0].children[0].offsetTop +
    (lineNum > 5 ? 100 : 0)
  );
}

export default function EditorPage({ showAuth, user, themeType, docsTree }) {
  let location = useLocation();
  let params = useParams();
  const [folderDetails, setFolderDetails] = useState({ id: "root" });
  let [searchParams, setSearchParams] = useSearchParams();
  const searchedBlock = searchParams.get("snippet");
  const folderPath = searchParams.get("path");

  useEffect(() => {
    if (folderPath && folderPath !== "") {
      const folderPathDetails = getPathDetailsFromPath(
        docsTree,
        folderPath.split("-")
      );
      if (folderPathDetails) {
        setFolderDetails(
          folderPathDetails[folderPathDetails.length - 1] || folderDetails
        );
      }
    }
  }, [docsTree]);

  const docId = location.pathname !== "/new-doc" && params.docId;
  const savedState = docId ? {} : getLocalDocument();
  const [markdownText, setMarkdownText] = useState(
    getMarkdownBlockText(searchedBlock) || savedState.content || NewMarkdownText
  );
  const [docName, setDocName] = useState(
    savedState.name || "Untitled Document"
  );
  const [slug, setSlug] = useState(savedState.slug || "new-doc");
  const [showPreview, setShowPreview] = useState(true);
  const [isPrivate, setIsPrivate] = useState(true);
  const [showPrivateModal, setShowPrivateModal] = useState(false);
  const [pageMessage, setPageMessage] = useState({
    fetchStatus: location.pathname === docId ? "loading" : "new",
  }); // new | loading | loaded | saving | saveSuccess | saveFailed
  const [oldDocumentState, setOldDocumentState] = useState({});

  const [pageTitle, setPageTitle] = useState("New Document");

  const [showReloadPrompt, setShowReloadPrompt] = useState(false);
  const [zenMode, setZenMode] = useState(false);

  const [showSnippetOptions, setShowSnippetOptions] = useState(false);

  const pageRef = useRef(null);

  const toggleZenMode = (enable) => {
    setZenMode(enable);
    if (enable) {
      pageRef.current.scrollIntoView();
      // window.scrollTo(0, pageRef.current.offsetTop);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const saveShowPreview = (show) => {
    setPreviewCookie(show);
    setShowPreview(show);
  };

  useEffect(() => {
    const savedPreview = getPreviewCookie();
    if (savedPreview) {
      setShowPreview(!!searchedBlock || savedPreview === "true");
    } else {
      setShowPreview(true);
      setPreviewCookie(true);
    }
  }, []);

  useEffect(() => {
    if (docId) {
      getDocument(docId)
        .then(({ data, status }) => {
          const doc = data;
          if (status === 200) {
            setOldDocumentState(doc);
            setDocName(doc.name);
            setSlug(doc.slug);
            setMarkdownText(doc.content);
            setIsPrivate(doc.private);
            setPageMessage({
              fetchStatus: "loaded",
              code: 200,
            });
            setPageTitle(doc.name);
            return;
          }
          setDocName("Untitled Document");
          setSlug("new-doc");
          setMarkdownText(NewMarkdownText);
          setIsPrivate(true);
          setOldDocumentState({});
          let msg = {
            code: status,
            fetchStatus: "loaded",
            type: "error",
          };
          if (status !== 403 && status !== 401) {
            msg.text = data.error;
          } else {
            showAuth();
          }
          setPageMessage(msg);
        })
        .catch((e) => {
          console.error(e);
          setPageMessage({
            type: "error",
            text: "Couldn't load the document",
            fetchStatus: "loaded",
            code: 500,
          });
          setOldDocumentState({});
        });
    } else {
      setPageTitle("New Document");
      setMarkdownText(
        getMarkdownBlockText(searchedBlock) ||
          savedState.content ||
          NewMarkdownText
      );
      setSlug(savedState.slug || "new-doc");
      setDocName(savedState.name || "Untitled Document");
      setIsPrivate(true);
      searchParams.append("snippet", "");
    }
  }, [docId]);

  const getUnsavedData = () => {
    let changes = {};
    if (slug === "") {
      return {};
    }
    if (docName !== oldDocumentState.name) {
      changes["name"] = docName;
    }
    if (slug !== oldDocumentState.slug) {
      changes["slug"] = slug;
    }
    if (markdownText !== oldDocumentState.content) {
      changes["content"] = markdownText;
    }
    if (isPrivate !== oldDocumentState.private) {
      changes["private"] = isPrivate;
    }
    return changes;
  };

  useLayoutEffect(() => {
    if (docId) return;
    setLocalDocument({
      name: docName,
      slug: slug,
      content: markdownText,
    });
  }, [docName, slug, markdownText]);

  useEffect(() => {
    const status = Object.keys(getUnsavedData()).length !== 0;
    setShowReloadPrompt(status);
  }, [docName, slug, markdownText, isPrivate, oldDocumentState]);

  const saveDocument = (directChanges) => {
    setPageMessage({
      fetchStatus: "saving",
    });
    if (docId) {
      updateDocument(docId, Object.assign({}, getUnsavedData(), directChanges))
        .then(({ data, status }) => {
          const doc = data;
          if (status === 200) {
            setOldDocumentState(doc);
            setDocName(doc.name);
            setSlug(doc.slug);
            setMarkdownText(doc.content);
            setIsPrivate(doc.private);
            setPageTitle(doc.name);
            setPageMessage({
              fetchStatus: "saveSuccess",
              type: "success",
              code: status,
            });
          } else {
            let msg = {
              fetchStatus: "saveFailed",
              type: "error",
              code: status,
            };
            if (status === 403 || status === 401) {
              showAuth();
            } else {
              msg.text = doc.error;
            }
            setPageMessage(msg);
          }
        })
        .catch((error) => {
          console.error(error);
          setPageMessage({
            fetchStatus: "saveFailed",
            type: "error",
            text: "Couldn't save the document",
            code: 500,
          });
        });
    } else {
      createDocument(getUnsavedData(), folderDetails?.id)
        .then(({ data, status }) => {
          if (status === 200) {
            setOldDocumentState(data);
            setPageMessage({
              fetchStatus: "saveSuccess",
              type: "success",
              code: status,
            });
            window.history.replaceState(
              "",
              data.name,
              `/me/docs/${data.id}/edit?${searchParams.toString()}`
            );
            setPageTitle(data.name);
            setLocalDocument({});
            window.location.reload();
          } else {
            if (status === 403 || status === 401) {
              showAuth();
            }
            setPageMessage({
              fetchStatus: "saveFailed",
              type: "error",
              code: status,
              text: data.error,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          setPageMessage({
            fetchStatus: "saveFailed",
            type: "error",
            text: "Couldn't save the document",
            code: 500,
          });
        });
    }
  };

  usePrompt(
    "You have unsaved changes. Are you sure you want to leave?",
    showReloadPrompt && docId
  );

  const publishedPath =
    user && user.username && docId
      ? `/${user.username || "<username>"}/${
          oldDocumentState.slug || "your-slug-here"
        }`
      : `/local/preview/${oldDocumentState.slug || "your-slug-here"}`;

  const previewContainerRef = useRef(null);

  return (
    <Page
      pageMessage={pageMessage}
      classNameSuffix="editor"
      className={zenMode ? "zen-enabled" : "zen-disabled"}
      displayTitle={false}
      title={pageTitle}
      noPadding
    >
      <div
        ref={pageRef}
        className={`editor-page-container ${
          showPreview ? "preview-enabled" : ""
        }`}
      >
        <div className="editor-page-sidebar">
          {user && !zenMode && (
            <Link
              className="all-docs-link"
              to={
                "/me/docs" +
                (folderDetails.id !== "root" ? "/" + folderDetails.id : "")
              }
            >
              {"< "}
              {showPreview
                ? ""
                : folderDetails.id !== "root"
                ? folderDetails.name
                : "All Documents"}
            </Link>
          )}
        </div>
        <div className="editor-section">
          <div className="editor-options">
            <div className="editor-options-row">
              <input
                id="doc-name"
                placeholder="Untitled Document"
                value={docName || ""}
                onChange={(e) => setDocName(e.target.value)}
              />
              <IntegrationInstructionsIcon
                className="clickable snippet-icon"
                onClick={(_) => setShowSnippetOptions(!showSnippetOptions)}
              />
              <Zen
                className="clickable zen-icon"
                onClick={(_) => toggleZenMode(!zenMode)}
              />
              <PreviewOn
                onClick={(e) => saveShowPreview(!showPreview)}
                className="clickable preview-icon"
              />
              <button
                onClick={(_) => saveDocument()}
                disabled={Object.keys(getUnsavedData()).length === 0}
                className="secondary"
              >
                Save
              </button>
              <button onClick={() => setShowPrivateModal(!showPrivateModal)}>
                {isPrivate ? <Private /> : <Public />}
                Share
              </button>
              <Modal
                header="Share"
                show={showPrivateModal}
                onClose={(_) => setShowPrivateModal(false)}
              >
                {isPrivate ? (
                  <>
                    <p>
                      Your document is currently private and can only be viewed
                      by you.
                    </p>
                    <button
                      onClick={() => {
                        saveDocument({ private: !isPrivate });
                        setShowPrivateModal(false);
                      }}
                    >
                      Make Public
                    </button>
                  </>
                ) : (
                  <>
                    <p>
                      Your document is currently public and can be viewed by
                      anyone at{" "}
                      <a
                        className="published-slug-link"
                        href={publishedPath}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {process.env.REACT_APP_HOST}
                        {publishedPath}
                        <OpenInNewIcon />
                      </a>
                    </p>
                    <button
                      onClick={() => {
                        saveDocument({ private: !isPrivate });
                        setShowPrivateModal(false);
                      }}
                    >
                      Make Private
                    </button>
                  </>
                )}
              </Modal>
            </div>
            <div className="editor-options-row slug-option">
              <span className="editor-options-row-label">
                slug
                <span style={{ color: "red" }} hidden={slug}>
                  *
                </span>
                :&nbsp;&nbsp;
              </span>
              <span className="slug-link-prefix">
                {process.env.REACT_APP_HOST}/
                {docId
                  ? user
                    ? user.username
                    : "<username>"
                  : "local/preview"}
                /
              </span>
              <input
                id="doc-slug"
                placeholder="<ADD-SLUG-FOR-DOC-URL_HERE>"
                value={slug || ""}
                onChange={(e) => {
                  setSlug(e.target.value.replaceAll(/[^-.~\w]/g, "-"));
                }}
              />

              <a
                className="published-slug-link"
                href={publishedPath}
                target="_blank"
                rel="noreferrer"
              >
                <OpenInNewIcon /> Published
              </a>
            </div>
          </div>
          <Editor
            theme={themeType}
            value={markdownText}
            showSnippetOptions={showSnippetOptions}
            onSnippetOptionsClosed={(_) => setShowSnippetOptions(false)}
            onChange={setMarkdownText}
            onCurrentLineChange={(num, _, lineHeight) => {
              // console.log(num, editorContainerRef.current.offsetTop);
            }}
            onScrollChange={(lineNum) => {
              previewContainerRef.current?.scrollTo({
                top: computePreviewScrollTop(lineNum, previewContainerRef),
                behavior: "smooth",
              });
            }}
          />
        </div>
        <div className="preview-section" hidden={!showPreview}>
          <div className="preview-options">
            <p className="doc-name">
              <a
                className="published-slug-link"
                href={publishedPath}
                target="_blank"
                rel="noreferrer"
              >
                <span>{docName || "Untitled Document"}</span>
                <OpenInNewIcon />
              </a>
            </p>
            <Close
              onClick={(e) => saveShowPreview(!showPreview)}
              className="clickable"
            />
          </div>
          <hr />
          <Preview
            theme={themeType}
            value={markdownText}
            previewContainerRef={previewContainerRef}
          />
        </div>
      </div>
    </Page>
  );
}
