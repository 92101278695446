import "./Header.scss";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as MenuIcon } from "../../icons/Menu.svg";
import { ReactComponent as InfoIcon } from "../../icons/Info.svg";
import { setLocalDocument } from "../../utils";

function ThemeTypeSelector({ theme, onThemeChange }) {
  return (
    <div
      className="theme-type-selector clickable"
      onClick={() => onThemeChange(theme === "light" ? "dark" : "light")}
    >
      <div className={`theme-type-selector-lever theme-lever-${theme}`} />
      {theme === "dark" && (
        <span className={`theme-type-selector-label theme-label-dark`}>🌚</span>
      )}
      {theme === "light" && (
        <span className={`theme-type-selector-label theme-label-light`}>
          🌞
        </span>
      )}
    </div>
  );
}

export default function Header({
  user,
  showAuth,
  themeType,
  onThemeTypeChange,
}) {
  const logout = function () {
    fetch("/api/logout").then(() => {
      setLocalDocument({});
      window.location = "/";
    });
  };

  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="header">
      <h2 className="app-name">
        <Link to={"/me/docs"}># Markdit</Link>
      </h2>
      <div className="header-options">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.markdit.com/appdocs/markdown-cheatsheet"
          className="cheatsheet-link"
        >
          Cheatsheet
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.markdit.com/appdocs/markdit"
        >
          <InfoIcon className="clickable" />
        </a>
        <ThemeTypeSelector
          theme={themeType}
          onThemeChange={onThemeTypeChange}
        />
        {(user && (
          <span className="header-user-name">
            <a href={`/${user.username}`}>{user.name}</a>
          </span>
        )) || <button onClick={showAuth}>Login</button>}
        {user && (
          <MenuIcon
            className="menu-icon clickable"
            onClick={() => setShowMenu(!showMenu)}
          />
        )}
      </div>

      <div className="header-menu" hidden={!showMenu}>
        <NavLink to="/me/docs" onClick={() => setShowMenu(false)}>
          All Documents
        </NavLink>
        <NavLink to="/new-doc" onClick={() => setShowMenu(false)}>
          New Document
        </NavLink>
        <p className="link clickable" onClick={logout}>
          Logout
        </p>
      </div>
    </div>
  );
}
