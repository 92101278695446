import CheatSheetContent from "./CheatSheetContent";
import Page from "../../components/Page";
import { useSearchParams } from "react-router-dom";

export default function CheatSheet({ themeType }) {
  let [searchParams, setSearchParams] = useSearchParams();
  let initSnippet = searchParams.get("snippet");
  const onSnippetSelected = (snippet) => {
    setSearchParams({ snippet: snippet });
  };
  return (
    <Page title="Markdown Cheatsheet">
      <CheatSheetContent
        theme={themeType}
        snippetActionText="Try it out"
        onSnippetAction={(snippet, snippetBlock) =>
          window.open(`/new-doc?snippet=${snippetBlock}`, "_blank")
        }
        initSnippet={initSnippet}
        onSnippetSelected={onSnippetSelected}
      />
    </Page>
  );
}
