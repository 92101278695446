const baseUrl = "/api";

function makeUrl(path) {
  return `${baseUrl}${path}`;
}

async function GetRequest(path) {
  let response = await fetch(makeUrl(path), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  response.data = await response.json();
  return response;
}

async function PostRequest(path, body) {
  let response = await fetch(makeUrl(path), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  response.data = await response.json();
  return response;
}

export async function signup(userdata) {
  return PostRequest("/signup", userdata);
}

export async function login(userdata) {
  return PostRequest("/auth", userdata);
}

export async function googleAuth(token) {
  return PostRequest("/auth/google", token);
}

export async function getCurrentUser() {
  return GetRequest("/users/me");
}

export async function updateCurrentUser(updatedFields) {
  return PostRequest("/users/me", updatedFields);
}

export async function getAllDocuments() {
  return GetRequest("/docs");
}

export async function getFolder(folderId) {
  return GetRequest(`/folders/${folderId}`);
}

export async function getRootDocsTree() {
  return GetRequest("/docs-tree");
}

export async function createDocument(docParams, parent) {
  return PostRequest(`/docs${parent ? "?parent=" + parent : ""}`, docParams);
}

export async function getDocument(docId) {
  return GetRequest(`/docs/${docId}`);
}

export async function updateDocument(docId, updatedFields) {
  return PostRequest(`/docs/${docId}`, updatedFields);
}

export function getDocumentForSlug(username, slug) {
  return GetRequest(`/users/${username}/docs/${slug}`);
}

export function getDocumentTreeForSlug(username, slug, type = "complete") {
  return GetRequest(`/users/${username}/docs/${slug}/tree?type=${type}`);
}

export async function getUser(username) {
  return GetRequest(`/users/${username}`);
}

export async function getUserDocuments(username) {
  return GetRequest(`/users/${username}/docs`);
}
