import { useEffect } from "react";
import { genClassName } from "../../utils";
import NotificationPopup from "../NotificationPopup";
import "./Page.scss";

function AuthError({ text, fetchStatus }) {
  return (
    <div className="in-page-message page-auth-error">
      <h2>Access failed</h2>
      <p>
        {text ||
          `Couldn't ${
            fetchStatus === "saveFailed" ? "save" : "load"
          } data. Make sure the data exists, you are logged in and have the access.`}
      </p>
    </div>
  );
}

function NotFoundError({ text }) {
  return (
    <div className="in-page-message page-notfound-error">
      <h2>Not Found</h2>
      <p>{text || "The resource you are looking for does not exist."}</p>
    </div>
  );
}

function InternalServerError({ text }) {
  return (
    <NotificationPopup
      text={text || "Some error occured. Please try again"}
      type="error"
    />
  );
}

function BadRequestError({ text }) {
  return <NotificationPopup title="Invalid Data" text={text} type="error" />;
}

function SaveSuccessful() {
  return <NotificationPopup title={"Saved successfully!"} type="success" />;
}

function PageMessageContent({
  fetchStatus = "loaded",
  code = 200,
  type = "success",
  text,
  notificationProps,
}) {
  if (type === "success" && fetchStatus === "loaded") {
    return "";
  }
  if (type === "success" && fetchStatus === "saveSuccess") {
    return <SaveSuccessful />;
  }
  if (type === "error" && (code === 401 || code === 403)) {
    return <AuthError fetchStatus={fetchStatus} text={text} />;
  }
  if (type === "error" && code === 404) {
    return <NotFoundError text={text} />;
  }
  if (type === "error" && code === 400) {
    return <BadRequestError text={text} />;
  }
  if (type === "error") {
    return <InternalServerError text={text} />;
  }
  if (type === "custom-notification") {
    return <NotificationPopup {...notificationProps} />;
  }
  return "";
}

function PageMessage(pageMessageProps) {
  return (
    <div className="page-message">
      <PageMessageContent {...pageMessageProps} />
    </div>
  );
}

export default function Page({
  title,
  breadcrumb,
  children,
  classNameSuffix,
  className,
  headerActions,
  pageMessage,
  displayTitle = true,
  noPadding = false,
}) {
  useEffect(() => {
    document.title = (title ? `${title} | ` : "") + "Markdit - Markdown Editor";
  }, [title]);
  return (
    <div
      className={
        genClassName("page", classNameSuffix, className) +
        (noPadding ? " no-padding" : "")
      }
    >
      {title && displayTitle && (
        <div className={genClassName("page-header", classNameSuffix)}>
          <h1 className={genClassName("page-title", classNameSuffix)}>
            {title}
          </h1>
          <div className={genClassName("page-header-actions", classNameSuffix)}>
            {headerActions}
          </div>
        </div>
      )}
      <PageMessage {...pageMessage} />
      <div className={genClassName("page-content", classNameSuffix)}>
        {children}
      </div>
    </div>
  );
}
