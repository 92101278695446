export function genClassName(
  elementClassName,
  classNameSuffix,
  additionalClasses = ""
) {
  return `${elementClassName} ${additionalClasses || ""} ${elementClassName}-${
    classNameSuffix || "-"
  }`;
}

export function dateTimeString(milliseconds) {
  if (!milliseconds) return "";
  const cdParts = new Date(milliseconds).toString().split(" ");
  return `${cdParts[2]} ${cdParts[1]} ${cdParts[3]}, ${cdParts[4]}`;
}

export function dateString(milliseconds) {
  if (!milliseconds) return "";
  const cdParts = new Date(milliseconds).toString().split(" ");
  return `${cdParts[1]} ${cdParts[2]}, ${cdParts[3]}`;
}

export function setLocalDocument(doc) {
  localStorage.setItem(
    "mdoc",
    JSON.stringify(Object.assign({ created_at: Date.now() }, doc))
  );
}

export function getLocalDocument() {
  let cookieVal = localStorage.getItem("mdoc");
  if (!cookieVal) return {};
  try {
    return JSON.parse(cookieVal);
  } catch (err) {
    return {};
  }
}

export function getCurrentPath(docsTree, folderId, parentPath = []) {
  if (docsTree.id === folderId) {
    return [...parentPath, folderId];
  }
  for (let i = 0; i < (docsTree.children?.length || 0); i++) {
    if (docsTree.children[i].id === folderId) {
      return [...parentPath, docsTree.id, folderId];
    }
  }
  for (let i = 0; i < (docsTree.children?.length || 0); i++) {
    const path = getCurrentPath(docsTree.children[i], folderId, [
      ...parentPath,
      docsTree.id,
    ]);
    if (path) {
      return path;
    }
  }
}

export function getPathDetailsFromPath(docsTree, path) {
  if (path.length === 0) {
    return;
  }
  if (path.length === 1) {
    if (docsTree.id !== path[0]) {
      return;
    }
    return [docsTree];
  }
  if (docsTree.id === path[0]) {
    for (let i = 0; i < (docsTree.children?.length || 0); i++) {
      let details = getPathDetailsFromPath(docsTree.children[i], path.slice(1));
      if (details) {
        details = [docsTree, ...details];
        return details;
      }
    }
  }
}

export function getPathDetails(docsTree, folderId) {
  const path = getCurrentPath(docsTree, folderId || "root");
  return getPathDetailsFromPath(docsTree, path || []);
}
